import React, { FC, lazy, useEffect, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdminPanel from '../pages/AdminPanel';
import Agents from '../pages/Agents';
import Campaigns from '../pages/Campaigns';
import Dashboard from '../pages/Dashboard';
import { useAuthContext } from '../context/AuthContext';
import { Subscriptions } from '../pages/Subscriptions';

const MainRoute = () => {
  const { user, userType } = useAuthContext();

  useEffect(() => {
    if (!user || user !== undefined) {
      <Redirect to="/" />;
    }
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {userType === 'agent' ? (
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Redirect to="/dashboard" />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/admin/dashboard" component={AdminPanel} />
          <Route exact path="/admin/campaigns" component={Campaigns} />
          <Route exact path="/admin/agents" component={Agents} />
          <Route exact path="/admin/subscription" component={Subscriptions} />
          <Redirect to="/admin/dashboard" />
        </Switch>
      )}
    </Suspense>
  );
};

export default MainRoute;
