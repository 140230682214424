'use strict';
import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Button, Select, Modal } from '@geist-ui/react';
import { Speaker, Mic, MicOff, Volume2, X, Settings, Pause, Play } from '@geist-ui/react-icons';
import TestMusic from '../email.mp3';
import { useModalContext } from '../context/ModalContext';
import { useThemeContext } from '../context/ThemeContext';
import { bufferToWave } from './audioToWave';

//------------------------SPEECH RECOGNITION-----------------------------
const socket = io.connect('https://dhwani-ai-server.gpu.dpi.one');

window.AudioContext = window.AudioContext || window.webkitAudioContext;

let audioContext = new AudioContext({ sampleRate: 16000 });
let audioInput = null,
  realAudioInput = null,
  inputPoint = null;

//------------------------COMPONENT-----------------------------

const Speech = ({ userString }) => {
  const { modalState, toggleModalState, setModalState } = useModalContext();
  const { themeType } = useThemeContext();
  const [listening, setListening] = useState(true);
  const [voices, setVoices] = useState([]);
  const [speaking, setSpeaking] = useState(false);
  const [convert, setConvert] = useState(null);
  const [counter, setCounter] = useState(0);
  const [audios, setAudios] = useState([]);

  // Modal states
  // start
  const [microphones, setMicrophones] = useState([]);
  const [speakers, setSpeakers] = useState([]);

  const [constraints, setConstraints] = useState(
    JSON.parse(localStorage.getItem('microphoneIn')) || {
      audio: {
        deviceId: { exact: 'default' },
      },
    }
  );
  const [speakerOut, setSpeakerOut] = useState(localStorage.getItem('speakerOut') || 'default');
  //end

  const selectionChangeHandler = (val) => {
    localStorage.setItem(
      'microphoneIn',
      JSON.stringify({
        audio: {
          deviceId: { exact: val },
        },
      })
    );
    return setConstraints({ audio: { deviceId: val ? { exact: val } : undefined } });
  };

  const speakerChangeHandler = (val) => {
    localStorage.setItem('speakerOut', val);
    return setSpeakerOut(val);
  };

  const processVoices = (voiceOptions) => {
    setVoices(voiceOptions);
  };

  const getVoices = () => {
    let voiceOptions = window.speechSynthesis.getVoices();
    if (voiceOptions.length > 0) {
      processVoices(voiceOptions);
      return;
    }

    window.speechSynthesis.onvoiceschanged = (event) => {
      voiceOptions = event.target.getVoices();
      processVoices(voiceOptions);
    };
  };

  useEffect(() => {
    // console.log('listening', listening);
    // console.log('speaking', speaking);

    if (speaking && listening) {
      // start recording
      socket.emit('start-recording', {
        numChannels: 1,
        bps: 16,
        fps: parseInt(audioContext.sampleRate),
        agent: userString,
      });
      socket.emit('ended', { cnt: 0 });
      console.log('start-recording', userString);
      initAudio();
      audioContext.resume();
    }
  }, [speaking, listening]);

  const toggleSpeaking = () => {
    console.log('toggled');
    if (speaking && listening) {
      socket.emit('ended', { cnt: -1 });
      socket.emit('end-recording', { agent: userString });
      window.location.reload();
    }
    setSpeaking((prev) => !prev);
    if (speaking === true) {
      setListening(true);
    }
  };

  // Called when initial components mounted to DOM

  useEffect(() => {
    if (!navigator.getUserMedia)
      navigator.getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    if (!navigator.cancelAnimationFrame)
      navigator.cancelAnimationFrame =
        navigator.webkitCancelAnimationFrame || navigator.mozCancelAnimationFrame;
    if (!navigator.requestAnimationFrame)
      navigator.requestAnimationFrame =
        navigator.webkitRequestAnimationFrame || navigator.mozRequestAnimationFrame;

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
      return;
    }
    navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(handleError);

    function downloadBlob(blob, name = 'file.wav') {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement('a');

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    }

    async function play(data) {
      const context = new AudioContext({ sampleRate: 16000 });
      const buffer = await context.decodeAudioData(data);
      // const source = context.createBufferSource();
      // source.buffer = buffer;
      // source.connect(context.destination);
      // source.start();

      var audio = new Audio();
      audio.src = URL.createObjectURL(bufferToWave(buffer, buffer.length));
      let outputSpeaker = localStorage.getItem('speakerOut');
      audio.setSinkId(outputSpeaker);
      audio.play();
    }

    function playSound(songBuffer) {
      console.log('🚀 ~ file: Speech.jsx ~ line 172 ~ playSound ~ songBuffer', songBuffer);
      var source = audioContext.createBufferSource();
      // creates a sound source
      source.buffer = songBuffer;
      // tell the source which sound to play
      source.connect(audioContext.destination);
      // connect the source to the context's destination (the speakers)
      source.start(0);
      // play the source now
    }

    socket.on('processed', (data) => {
      console.log('socket-processed', data);
      const audioBlob = new Blob([data.data], { type: 'audio/wav' });
      const audioURL = URL.createObjectURL(audioBlob);
      var myAudio = new Audio(audioURL);
      // setAudios((prev) => [...prev, myAudio]);
      myAudio.play();
      myAudio.addEventListener('ended', function () {
        myAudio.currentTime = 0;
        socket.emit('ended', { cnt: data.cnt });
      });
    });
  }, []);

  // useEffect(() => {
  //   if (audios.length > 0) {
  //     for (const a of audios) {
  //       a.play();
  //       a.addEventListener('ended', function () {
  //         a.currentTime = 0;
  //         console.log('ended');
  //         setAudios((prev) => prev.slice(1));
  //       });
  //     }
  //   }
  // }, []);

  function attachSinkId(element, sinkId) {
    console.log(
      '🚀 ~ file: Speech.jsx ~ line 215 ~ attachSinkId ~ element, sinkId',
      element,
      sinkId
    );
    // if (typeof element.sinkId !== 'undefined') {
    //   element
    //     .setSinkId(sinkId)
    //     .then(() => {
    //       console.log(`Success, audio output device attached: ${sinkId}`);
    //       // element.play();
    //     })
    //     .catch((error) => {
    //       let errorMessage = error;
    //       if (error.name === 'SecurityError') {
    //         errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
    //       }
    //       console.error(errorMessage);
    //       // Jump back to first output device in the list as it's the default.
    //       // audioOutputSelect.selectedIndex = 0;
    //     });
    // } else {
    //   console.warn('Browser does not support output device selection.');
    // }
  }

  function gotDevices(deviceInfos) {
    // console.log('🚀 ~ file: Speech.jsx ~ line 237 ~ gotDevices ~ deviceInfos', deviceInfos);
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];

      let obj = {
        value: deviceInfo.deviceId,
      };
      if (deviceInfo.kind === 'audioinput') {
        obj.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
        // console.log('audio device input', { obj });
        setMicrophones((prev) => [...prev, obj]);
      } else if (deviceInfo.kind === 'audiooutput') {
        obj.text = deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
        // console.log('audio device output', { obj });
        setSpeakers((prev) => [...prev, obj]);
      } else {
        // console.log('Some other kind of source/device: ', deviceInfo);
      }
    }
  }

  // function testStream(stream) {
  // 	window.stream = stream; // make stream available to console
  // 	var audioElement = document.getElementById('audio');
  // 	// attachSinkId(audioElement, speakerOut);
  // 	audioElement.srcObject = stream;
  // 	audioElement.onloadedmetadata = function (e) {
  // 		audioElement.play();
  // 	};

  // 	// Refresh button list in case labels have become available
  // 	return navigator.mediaDevices.enumerateDevices();
  // }

  // useEffect(() => {
  // 	console.log('constraints', constraints);
  // 	navigator.mediaDevices.getUserMedia(constraints).then(testStream).catch(handleError);
  // }, [constraints]);

  function play() {
    var audio = document.getElementById('audio');
    attachSinkId(audio, localStorage.getItem('speakerOut'));
    audio.play();
  }

  return (
    <div style={container}>
      <button
        id="microphone-btn"
        style={speaking ? { ...button, ...stop } : { ...button, ...start }}
        onClick={toggleSpeaking}
      >
        {speaking ? <MicOff color="#fff" size={36} /> : <Mic color="#fff" size={36} />}
      </button>

      {/* <audio id="output" src={TestMusic} /> */}
      <audio id="audio" src={TestMusic} />

      {/* <button onClick={() => console.log(audios)}>Check</button> */}

      <div className="flex flex-row space-x-1 mt-1">
        {convert !== null && (
          <Button
            icon={convert ? <Pause /> : <Play />}
            auto
            onClick={() => setConvert((prev) => !prev)}
          >
            {convert ? 'Pause Conversion' : 'Start Conversion'}
          </Button>
        )}
      </div>
      <Modal
        open={modalState}
        onClose={() => setModalState(false)}
        width={'50vw'}
        disableBackdropClick={true}
      >
        <div className="flex">
          <div
            className={`flex flex-col  items-start flex-grow
					${themeType === 'light' ? 'border-r' : 'dark_theme_line'}
					`}
          >
            <p className="text-xl m-0 p-2 pl-5 pt-10">Settings</p>
            <div
              className={`flex mt-5 
							${themeType === 'light' ? 'bg-gray-200' : 'bg-gray-700'}
							w-full p-2 pl-5 relative`}
              style={{
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
                right: 5,
              }}
            >
              <Speaker size={24} />
              <p className="text-md m-0 ml-2">Audio</p>
            </div>
            {/* <div
							className={`flex mt-2 
							${themeType === 'light' ? 'hover:bg-gray-200' : 'hover:bg-gray-700'}
							w-full p-2 pl-5 pr-7 relative`}
							style={{
								borderTopRightRadius: 25,
								borderBottomRightRadius: 25,
								right: 5,
							}}
						>
							<Settings size={24} />
							<p className="text-md m-0 ml-2">General</p>
						</div> */}
          </div>
          <div
            className="flex flex-col"
            style={{
              flexGrow: 4,
            }}
          >
            <div
              className="p-2 flex justify-end cursor-pointer"
              onClick={() => setModalState(false)}
            >
              <X size={24} />
            </div>
            <div className="text-gray-500 p-0 mt-2 flex items-start ml-5 flex-col">
              <p className="text-sm font-semibold">Microphone</p>
              <div className="w-full flex justify-between">
                <div
                  className="border-b"
                  style={{
                    width: '60%',
                  }}
                >
                  <Select
                    placeholder="Choose one"
                    onChange={selectionChangeHandler}
                    dropdownStyle={{
                      border: 'none',
                    }}
                    width="100%"
                    initialValue={constraints.audio.deviceId.exact}
                  >
                    {microphones.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.text}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="pr-8">
                  <Mic size={24} color={`${themeType === 'light' ? '#000' : '#fff'}`} />
                </div>
              </div>
            </div>

            <div className="text-gray-500 p-0 mt-2 flex items-start ml-5 flex-col">
              <p className="text-sm font-semibold">Speakers</p>
              <div className="w-full flex justify-between">
                <div
                  className="border-b"
                  style={{
                    width: '60%',
                  }}
                >
                  <Select
                    placeholder="Choose one"
                    onChange={speakerChangeHandler}
                    dropdownStyle={{
                      border: 'none',
                    }}
                    width="100%"
                    initialValue={speakerOut}
                  >
                    {speakers.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.text}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="pr-8">
                  <div onClick={play} className="cursor-pointer">
                    <Volume2 size={24} color={`${themeType === 'light' ? '#000' : '#fff'}`} />
                  </div>
                </div>
              </div>
            </div>

            <div className="h-52"></div>
          </div>
        </div>
      </Modal>
    </div>
  );

  //? Extra utils func
  //-start

  function convertToMono(input) {
    let splitter = audioContext.createChannelSplitter(2);
    let merger = audioContext.createChannelMerger(2);

    input.connect(splitter);
    splitter.connect(merger, 0, 0);
    splitter.connect(merger, 0, 1);
    return merger;
  }

  function gotStream(stream) {
    console.log('Got stream');
    inputPoint = audioContext.createGain();

    // Create an AudioNode from the stream.
    realAudioInput = audioContext.createMediaStreamSource(stream);
    audioInput = realAudioInput;

    audioInput = convertToMono(audioInput);
    audioInput.connect(inputPoint);

    let scriptNode = (audioContext.createScriptProcessor || audioContext.createJavaScriptNode).call(
      audioContext,
      2048,
      1,
      1
    );
    console.log('🚀 ~ file: Speech.jsx ~ line 401 ~ gotStream ~ scriptNode', scriptNode);
    scriptNode.onaudioprocess = function (audioEvent) {
      console.log('speaking', speaking, listening);
      if (speaking && listening) {
        let input = audioEvent.inputBuffer.getChannelData(0);

        // convert float audio data to 16-bit PCM
        let buffer = new ArrayBuffer(input.length * 2);
        let output = new DataView(buffer);
        for (let i = 0, offset = 0; i < input.length; i++, offset += 2) {
          let s = Math.max(-1, Math.min(1, input[i]));
          output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
        }
        socket.emit('write-audio', { buffer: buffer, agent: userString });

        // console.log('write-audio');
      }
    };
    inputPoint.connect(scriptNode);
    scriptNode.connect(audioContext.destination);

    let zeroGain = audioContext.createGain();
    zeroGain.gain.value = 0.0;
    inputPoint.connect(zeroGain);
    zeroGain.connect(audioContext.destination);
  }

  function initAudio() {
    if (!navigator.getUserMedia)
      navigator.getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    if (!navigator.cancelAnimationFrame)
      navigator.cancelAnimationFrame =
        navigator.webkitCancelAnimationFrame || navigator.mozCancelAnimationFrame;
    if (!navigator.requestAnimationFrame)
      navigator.requestAnimationFrame =
        navigator.webkitRequestAnimationFrame || navigator.mozRequestAnimationFrame;
    // setConvert(true);
    navigator.getUserMedia(constraints, gotStream, function (e) {
      alert('Error getting audio');
      console.log(e);
    });
  }

  function handleError(error) {
    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
  }

  //-end
};

export default Speech;

//-------------------------CSS------------------------------------

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
  start: {
    background: 'teal',
  },
  stop: {
    background: '#d90429',
  },
};

const { container, button, start, stop } = styles;
