import { Card, Grid, Image, Note, Page, Select, Spinner } from '@geist-ui/react';
import React from 'react';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar';
import Speech from '../components/Speech';
import { fetchListData } from '../constants/requests';
import { useThemeContext } from '../context/ThemeContext';
import AvatarImage from '../images/avatar.svg';

const Dashboard = () => {
  const { switchThemesHandler } = useThemeContext();
  const [userString, setUserString] = React.useState('');
  const handler = (val) => console.log(val);

  React.useEffect(() => {
    fetchListData('agents').then((res) => setUserString(`${res.added_by}_${res.username}`));
  }, []);

  return (
    <>
      <Navbar toggleDarkMode={switchThemesHandler} isLoggedIn={true} />
      <Page dotBackdrop={false} size="mini" className="cmainclass-dashboard">
        <Grid.Container gap={12} justify="center">
          <Card shadow style={{ maxHeight: '80vh' }}>
            <div
              style={{
                textAlign: 'center',
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                <Note type="secondary" label={false} style={{ maxWidth: '' }}>
                  Click on the microphone icon and begin speaking.
                </Note>
              </div>
              <div>
                <Image src={AvatarImage} height={120} width={120} />
              </div>
              <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                <Select placeholder="Select voice" onChange={handler}>
                  <Select.Option value="1">Male</Select.Option>
                  <Select.Option value="2">Female</Select.Option>
                </Select>
              </div>
              {userString !== '' ? <Speech userString={userString} /> : <Spinner />}
            </div>
          </Card>
        </Grid.Container>
      </Page>
      <Footer />
    </>
  );
};

export default Dashboard;
