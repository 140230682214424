import { Avatar, Button, Link, Popover, useTheme } from '@geist-ui/react';
import User from '@geist-ui/react-icons/user';
import * as Icons from '@geist-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { useModalContext } from '../context/ModalContext';
import makeStyles from '../utils/makeStyles';

import logo from '../images/dhwani.svg';

const useStyles = makeStyles((ui) => ({
  header: {
    // width: ui.layout.pageWidthWithMargin,
    width: '100%',
    margin: '0 auto',
    backgroundColor: ui.palette.background,
    fontSize: 16,
    height: 60,
    zIndex: 15,
    position: 'sticky',
    top: 0,
    backgroundColor: ui.palette.background,
    borderBottom: `solid 1px ${ui.palette.accents_2}`,
    zIndex: 15,
  },
  headerContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${ui.layout.pageMargin}`,
  },
  headerTitle: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
  },
  navFixed: {
    borderBottom: ui.type === 'light' && 'none',
    boxShadow: ui.type === 'light' && 'rgba(0, 0, 0, 0.1) 0 0 15px 0',
  },
  navContent: {
    width: ui.layout.pageWidthWithMargin,
    maxWidth: '100%',
    height: '100%',
    margin: '0 auto',
    '& .tabs header': {
      padding: `0 ${ui.layout.pageMargin}`,
      borderBottom: 'none !important',
      flexWrap: 'nowrap !important',
      overflowY: 'hidden',
      overflowX: 'auto',
      overflow: '-moz-scrollbars-none',
      '-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '& .content': {
      display: 'none !important',
    },
    '& .tab': {
      padding: '12px !important',
      margin: '0 !important',
      fontSize: '14px !important',
    },
  },
  sidebar: {
    display: 'flex',
    alignItems: 'center !important',
  },
  themeIcon: {
    height: '40px !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    padding: '0 !important',
  },
  popover: {
    width: '180px !important',
  },
}));

const Navbar = ({ toggleDarkMode, isLoggedIn }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { removeUserInfo, userType } = useAuthContext();
  const { toggleModalState } = useModalContext();
  const [fixed, setFixed] = useState(false);

  const isDark = theme.type === 'dark';

  const handleLogout = (e) => {
    e.preventDefault();
    removeUserInfo();
    history.push('/login');
  };

  const popoverContent = () => {
    if (userType === 'agent') {
      return (
        <>
          <Popover.Item className="cursor-pointer" onClick={toggleModalState}>
            <span>Settings</span>
          </Popover.Item>
          <Popover.Item>
            <Link onClick={handleLogout}>Logout</Link>
          </Popover.Item>
        </>
      );
    } else {
      return (
        <Popover.Item className="cursor-pointer" onClick={toggleModalState}>
          <span>Profile</span>
        </Popover.Item>
      );
    }
  };

  useEffect(() => {
    const scrollHandler = () => {
      const shouldFixed = document.documentElement.scrollTop > 60;
      if (fixed !== shouldFixed) setFixed(shouldFixed);
    };
    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  }, [fixed]);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <div style={{ display: 'flex' }}>
            <img
              src={logo}
              alt=""
              srcSet=""
              style={{
                width: '40px',
                height: '40px',
              }}
            />
            <div className={classes.headerTitle}>Dhwani</div>
          </div>
          <div className={classes.sidebar}>
            <Button
              aria-label="Toggle Dark mode"
              style={{
                marginRight: isLoggedIn ? 15 : 0,
              }}
              className={classes.themeIcon}
              auto
              type="abort"
              onClick={toggleDarkMode}
            >
              {isDark ? <Icons.Sun size={20} /> : <Icons.Moon size={20} />}
            </Button>
            {isLoggedIn && (
              <Popover
                className="cursor-pointer"
                content={popoverContent}
                placement="bottomEnd"
                portalClassName={classes.popover}
              >
                <div
                  aria-label="Profile Button"
                  className="p-2 bg-gray-100 rounded-full h-5 w-5 flex items-center"
                >
                  <User size={20} color="#000" />
                </div>
              </Popover>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
